.date-picker-custom-parent {
    width: 100%;
}

.date-picker-custom-parent svg {
    line-height: 1;
    text-align: center;
    height: 65%;
}

.date-picker-custom {
    width: 100%;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease, border-color .1s ease;
    height: 37.094px;
}

.uploadFile {
    cursor: pointer;
    background-color: rgb(194, 200, 207);
    padding: 10px;
    border-radius: 5px;
    width: 100px;
    background-color: #1b1c1d;
    color: #fff;
    text-shadow: none;
    background-image: none;
    font-size: 1rem;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ui.container {
        width: 933px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

