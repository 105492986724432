.google-lavander {
    color: #A4BDFC ;
}
.google-sage {
    color: #7AE7BF ;
}
.google-grape {
    color: #DBADFF ;
}
.google-flamingo {
    color: #FF887C ;
}
.google-banana {
    color: #FBD75B ;
}
.google-tengerine {
    color: #FFB878 ;
}
.google-peacock {
    color: #46D6DB ;
}
.google-graphite {
    color: #E1E1E1 ;
}
.google-blueberry {
    color: #5484ED ;
}
.google-basil {
    color: #51B749 ;
}
.google-tomato {
    color: #DC2127 ;
}
